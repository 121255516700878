.z-game-download {
    cursor: pointer;
    font-size: 1.6em;
    background-color: rgb(255, 0, 0);
    border-radius: 0.2em;
    padding: 0.2em 0.8em;
    color: #ffffff;
    width: fit-content;
    margin-top: 1em;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    display: flex;
    font-family: Rexlia;
    margin-right: 0.4em;
    flex-direction: row;
    align-items: center;

}

.z-game-download-disabled {
    cursor: default;
    font-size: 1.6em;
    background-color: rgb(152, 19, 19);
    border-radius: 0.2em;
    padding: 0.2em 0.8em;
    color: #858585;
    width: fit-content;
    margin-top: 1em;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    display: flex;
    font-family: Rexlia;
    margin-right: 0.4em;
    flex-direction: row;
    align-items: center;
}

.z-game-download-container {
    display: flex;
    flex-direction: row;
}

.z-game-download-icon {
    height: 1em;
    margin-right: 0.4em;
    color: #858585;
}

.z-game-download-wrapper {
    display: flex;
    flex-direction: column;
}

.z-game-available-in {
    font-size: 0.8em;
    color: #FFFFFF;
    padding-top: 0.4em;
}

.z-swap-title {
    font-family: Rexlia;
    color: rgb(255, 0, 0);
    font-size: 1.6em;
    padding: 1em 0;
}

.z-swap-container {
    border: 1px solid rgb(255, 0, 0);
    width: 32em;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    padding: 2em;
    position: relative;
}

.z-swap-container-mask {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 24px;
}

.z-swap-container-mask-hint-text {
    color: rgb(255, 0, 0);
    margin-bottom: 0.6em;
}

.z-swap-container-mask-hint {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
}

.z-swap-container-connect-btn {
    background-color: #FF0000;
    color: #FFE9A0;
    padding: 0.4em 2em;
    text-align: center;
    width: fit-content;
    border-radius: 0.2em;
    cursor: pointer;
}

.z-swap-sell-coin-title-container {
    margin-bottom: 0.4em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.z-swap-sell-coin-title {
    color: #FFE9A0;
    font-size: 1.4em;
    display: flex;
    flex-direction: row;
}

.z-swap-sell-coin-balance {
    color: #FFE9A0;
    font-size: 1em;
}

.z-swap-coin-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.z-swap-arrow-container {
    text-align: center;
    padding: 1em;
}

.z-swap-transaction-arrow {
    width: 3em;
}

.z-swap-amount-input {
    border-radius: 16px;
    height: 64px;
    border: 1px solid rgb(255, 0, 0);
    background: transparent;
    caret-color: red;
    color: rgb(255, 0, 0);
    padding: 0 1em;
    text-align: right;
    font-size: 1.2em;
}

.z-swap-seller-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em 0;
}

.z-swap-select-arrow-icon {
    width: 1em;
    cursor: pointer;
}

.z-swap-btn {
    color: #FFE9A0;
    background-color: #ff0000;
    width: 100%;
    padding: 0.6em 0;
    cursor: pointer;
    border-radius: 12px;
    text-align: center;
    font-size: 1.2em;
}

.z-swap-btn-disabled {
    color: #ffffff;
    background-color: gray;
    width: 100%;
    padding: 0.8em 0;
    cursor: pointer;
    border-radius: 12px;
    text-align: center;
}

.z-swap-btn-margin-right {
    margin-right: 0.4em;
}

.z-swap-btn-margin-left {
    margin-left: 0.4em;
}

.z-swap-enable-btn-container {
    width: 100%;
    margin-top: 1em;
    display: flex;
    flex-direction: row;
}

.z-swap-price-container {
    display: flex;
    flex-direction: row;
    color: #ff0000;
    padding: 0.4em 0;
}

.z-swap-price-tag {
    color: #ff0000;
}

.z-wallet-icon {
    width: 1.2em;
    margin-right: 0.6em;
}

.z-fair-launch-description-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 2em;
}

.z-swap-connected-account-text {
    font-size: 1em;
    color: #ff0000;
}

.z-armory-section-title {
    color: #FF0000;
}

.z-armory-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.z-armory-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2em;
}

.z-armory-blind-box-container {
    display: flex;
    flex-direction: column;
}

.z-armory-empty-text {
    color: #FFE9A0;
    opacity: 0.5; /* Firefox */
    font-size: 1.2em;
    padding: 2em;
}

.z-armory-blind-box-text {
    color: #FFE9A0;
    font-size: 1.2em;
    padding: 2em;
}

.z-armory-normal-blind-box-text {
    color: #31A2FF;
    font-size: 1.2em;
    padding: 2em;
}

.z-armory-elite-blind-box-text {
    color: #F68C00;
    font-size: 1.2em;
    padding: 2em;
}


.z-armory-legendary-blind-box-text {
    color: #B10072;
    font-size: 1.2em;
    padding: 2em;
}

.z-armory-weapon-case-des {
    color: #FFE9A0;
    font-size: 1.2em;
    margin-top: 2em;
}

.z-nft-important {
    width: 1.2em;
    vertical-align: bottom;
    margin-right: 0.4em;
}

.z-armory-weapon-case-title {
    color: #FF0000;
    font-size: 1.4em;
}

.z-armory-section {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #000000;
    overflow: hidden;
    padding: 6em 4em;
}

.z-armory-weapon-case-intro-container {
    width: 100%;
}

.z-armory-weapon-case-container {
    display: table;
    flex-direction: column;
}

.z-armory-weapon-case-container-row {
    display: table-row;
    margin-top: 2em;
}

.z-weapon-case-img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 0;
}

.z-normal-weapon-case-label {
    color: #31A2FF;
    text-shadow: 0 0 8px #31A2FF;
}

.z-elite-weapon-case-label {
    color: #F68C00;
    text-shadow: 0 0 8px #F68C00;
}

.z-armory-elite-prize-label {
    color: #F68C00;
}

.z-armory-legendary-prize-label {
    color: #B10072;
}

.z-armory-normal-prize-label {
    color: #31A2FF;
    padding-top: 0.4em;
}

.z-armory-normal-prize-img-container {
    width: fit-content;
    height: 7em;
    position: relative;
}

.z-armory-legendary-prize-img-container {
    width: fit-content;
    height: 10em;
    position: relative;
}


.z-armory-elite-prize-img-container {
    width: fit-content;
    height: 9em;
    position: relative;
}

.z-legendary-weapon-case-label {
    color: #B10072;
    text-shadow: 0 0 8px #B10072;
}

.z-weapon-case-img {
    width: 8em;
    margin-bottom: 0.4em;
}

.z-armory-arrow-cell {
    display: table-cell;
    padding: 2em 1em;
    vertical-align: middle;
}

.z-armory-arrow-img {
    width: 3em;
}

.z-armory-prize-cell {
    display: table-cell;
    padding: 0 1em;
    vertical-align: middle;
}

.z-armory-prize-img {
    width: 10em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.z-armory-prize-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 10em;
}

.z-fair-launch-rule {
    font-size: 1.2em;
    padding-top: 0.8em;
    color: #FFE9A0;
}

.z-swap-add-coin-to-wallet-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF0000;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    margin-left: 0.4em;
    cursor: pointer;
}

.z-swap-add-coin-to-wallet-img {
    width: 2.4em;
}