.App {
    text-align: center;
    overflow-x: hidden;
}

body {
    overflow: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

* {
    font-family: Rexlia;
    color: #FFE9A0;
    /*touch-action:none;*/
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.one {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto !important;
    position: relative;
}

.two {
    background-color: #000;
    position: relative;
    height: fit-content;
}

.three {
    background-color: #000;
    position: relative;
    height: auto;
}

.four {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    padding: 6em 4em;
}

.container {
    scroll-snap-type: y mandatory;
    overflow-y: auto;
    height: 100vh;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.z-header-container {
    left: 0;
    min-height: 4em;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 100;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 212, 255, 0) 100%);
}

.z-main-logo {
    width: 3em;
    padding: 1.5em;
}

.z-header-menu-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.z-header-menu-item {
    padding: 0 0.6em;
    color: #ff0000;
    font-size: 1.2em;
    font-family: Rexlia;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.z-header-menu-item:hover {
    text-shadow: #ff0000 1px 0 10px;
}


@font-face {
    font-family: Rexlia;
    src: url(./font/rexlia-rg.otf);
}

.z-fog-anim-body {

}


.z-fog-img {
    opacity: 0.5;
}

/*video {*/
/*    width: 100vw !important;*/
/*    height: auto !important;*/
/*    position: sticky !important;*/
/*}*/

.z-main-video {
    width: 100vw !important;
    height: auto !important;
    position: sticky !important;
}


.z-weapon-video {
    width: 100vw !important;
    height: auto !important;
    position: sticky !important;
}


.z-video-container {
    width: 100vw !important;
    height: auto !important;
    position: sticky !important;
}

.video-js {
    height: auto;
}

.z-join-whitelist {
    cursor: pointer;
    font-size: 1.8em;
    background-color: rgb(255, 0, 0);
    border-radius: 0.2em;
    padding: 0.2em 0.8em;
    color: #FFE9A0;
    width: fit-content;
    margin-top: 1em;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    display: flex;
    font-family: Rexlia;
}


.z-join-whitelist:active {
    transform: scale(0.95);
}

.z-join-whitelist:hover {
    box-shadow: 1px 1px 2px rgb(33, 0, 0), 0 0 1em rgb(157, 0, 0), 0 0 0.2em rgb(33, 0, 0);
}

.z-connect-wallet {
    background-color: rgb(255, 38, 0);
    padding: 0 1em;
    border-radius: 0.2em;
    color: #FFE9A0;
    margin-right: 1.2em;
    height: 2em;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    font-family: Rexlia;
    display: flex;
    align-items: center;
}

.z-connect-wallet:active, .z-swap-btn:active, .zm-swap-btn:active, .z-swap-container-connect-btn:active, .z-swap-add-coin-to-wallet-btn:active, .zm-swap-add-coin-to-wallet-btn:active, .zm-pitch-deck-btn:active {
    transform: scale(0.95);
}

.disable-select {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.hide {
    /*visibility: hidden;*/
    display: none;
}


.z-swap-btn-container {
    width: 100%;
    text-align: center;
    margin-top: 1em;
}

.z-world-logo-container {
    position: absolute;
    left: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translate(0, -50%);
    height: 100%;
    padding: 0 1em;
}

.z-world-logo {
    width: 25em;
}

.z-welcome-to-zworld {
    color: rgb(255, 0, 0);
    font-size: 2em;
    font-family: Rexlia;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    text-shadow: #c70000 1px 0 10px;
}

.z-header-menu-sound {
    background-color: rgb(255, 0, 0);
    padding: 0 0.8em;
    border-radius: 0.2em;
    color: #ffffff;
    margin: 0 0.8em;
    height: 2em;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    display: flex;
}

.z-header-menu-sound:active {
    transform: scale(0.9);
    /* Scaling button to 0.98 to its original size */
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    /* Lowering the shadow */
}

.z-header-menu-sound-img {
    width: 1.2em;
    line-height: 2em;
}

.z-world-logo-bg-container {
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: 50;
    left: 0;
    top: 0;
    height: 100%;
    background-image: url("./img/web_logo_bg.png");
}

.z-world-logo-bg-img {
    position: absolute;
    z-index: -1;
    left: 0;
    height: 100%;
}


.z-nft-section-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    direction: rtl;
    z-index: 100;
    padding: 6em 4em;
    align-items: center;
}

.z-weapon-section-container {
    display: flex;
    flex-direction: column;
    /*position: absolute;*/
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    direction: rtl;
    z-index: 100;
    padding: 6em 4em;
    align-items: center;
}

.z-weapon-section-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.z-section-title {
    font-family: Rexlia;
    color: #ff0000;
    font-size: 2em;
    padding: 0.4em;
    /*text-shadow: #420000 1px 0 10px;*/
    z-index: 100;
    text-shadow: 0 0 16px #FF0000;
}

.z-armory-account {
    font-family: Rexlia;
    color: #ff0000;
    font-size: 1em;
    padding: 0.4em;
    /*text-shadow: #420000 1px 0 10px;*/
    z-index: 100;
    text-shadow: 0 0 16px #FF0000;
}

.z-fair-launch-section-title {
    font-family: Rexlia;
    color: #ff0000;
    font-size: 2em;
    z-index: 100;
    text-shadow: 0 0 16px #FF0000;
}

.z-section-sub-title {
    font-family: Rexlia;
    color: #FFE9A0;
    font-size: 1.2em;
    padding: 0 0.4em;
    text-align: center;
}

.z-section-nft-sub-title {
    font-family: Rexlia;
    color: #FFE9A0;
    font-size: 1.2em;
    padding: 0 0.6em;
}

.z-section-gameview-container {
    position: relative;
    flex: 1;
}

.z-gameview-wrapper {
    position: absolute;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.z-desktop-screen-img {
    height: 50%;
}

.z-gameplay-img {
    width: 100%;
}

.z-nft-section {
    position: relative;
    background-color: #000000;
    overflow: hidden;
    padding: 6em 4em;
}

.z-nft-card-container {
    /*position: absolute;*/
    height: 60%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4em;
}

.z-hr {
    background: radial-gradient(49.07% 49.07% at 50% 50.93%, rgba(255, 0, 0, 0.92) 0%, #310000 100%);
    width: 100%;
    height: 1.5px;
}

.z-section-header {
    color: #FF0000;
    font-size: 2em;
    text-transform: uppercase;
    z-index: 100;
    font-family: Rexlia;
    text-shadow: #9d0000 0px 0 20px;
    font-weight: bold;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #FFE9A0;
    opacity: 0.5; /* Firefox */
}

.z-members-container {
    display: flex;
    flex-direction: column;
    z-index: 100;
    width: 100%;
}

.z-token-chart-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    z-index: 100;
}

.z-partners-container {
    display: flex;
    flex-direction: column;
    z-index: 100;
}

.z-members-row {
    display: flex;
    flex-direction: row;
}


.z-roadmap-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.z-partners-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.z-member-card {
    display: flex;
    flex-direction: column;
    width: 25%;
    margin: 1em;
    align-items: center;
    border-right: #9D0000;
    border-left: #9D0000;
}

.z-roadmap-block {
    display: flex;
    flex-direction: column;
    width: 24em;
    align-items: center;
    border-right: #9D0000;
    border-left: #9D0000;
}

.z-partner-card {
    display: flex;
    flex-direction: column;
    width: 15em;
    height: 5em;
    margin: 1.6em;
    align-items: center;
    border-right: #9D0000;
    border-left: #9D0000;
    justify-content: center;
}

.z-member-img {
    width: 12em;
    border-radius: 1em;
    border: 0.4em solid #ff0000;
    padding: 0.4em;
}

.z-partner-img {
    max-width: 15em;
    max-height: 5em;
    border-radius: 1em;
}

.z-member-exp {
    color: #FFE9A0;
    font-size: 0.8em;
    text-align: center;
    padding: 0.4em 1.5em;
}

.z-member-title {
    color: #ff0000;
    font-size: 0.8em;
    text-align: center;
    padding: 0 1.5em;
    font-weight: bold;
}

.z-member-name {
    margin-top: 0.4em;
    color: #FFE9A0;
    font-size: 1.2em;
    text-align: center;
    padding: 0 1.5em;
    font-weight: bold;
}

.z-section-sub-header {
    color: #FFE9A0;
    font-size: 1.2em;
    width: 40em;
    text-align: center;
    margin-bottom: 2em;
    z-index: 100;
}

.z-audition-img {
    width: 12em;
}

.z-timeline {
    width: 100%;
    height: 8px;
    background: #9b0000;
    border-top: 1px solid rgba(255, 0, 0, 1);
    border-bottom: 1px solid rgba(255, 0, 0, 1);
    position: relative;
}

.z-roadmap-title {
    color: #FFE9A0;
    font-size: 1.6em;
    padding: 2em;
}

.z-timeline-dot {
    width: 1.8em;
    position: absolute;
    top: -0.6em;
    left: -0.9em;
    border-radius: 50%;
    background-color: #ffedb3;
    box-shadow: 0 0 6px 3px #ffedb3, /* inner white */ 0 0 10px 6px #ff0000, /* middle magenta */ 0 0 14px 9px #ff0000; /* outer cyan */
}

.z-timeline-content-container {
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, #bb0000 0%, rgba(10, 10, 10, 0) 94.34%);
    width: 100%;
    color: #ffedb3;
    padding: 1em 0;
}

.z-timeline-item {
    padding: 0.6em 1.6em;
    font-size: 1.2em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.z-timeline-check-icon {
    width: 1.2em;
    margin-right: 0.8em;
}

.z-token-chart-items {
    display: grid;
    grid-template-columns: auto auto;
    grid-column: 2;
    padding: 0 1em;
    flex: 1;
}

.z-token-chart-overview {
    display: flex;
    flex-direction: column;
    padding: 0 1.6em;
    flex: 1;
}

.z-token-chart-overview-card {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.5em;
    padding: 1em 1.6em;
    width: 16em;
    margin: 1em;
}

.z-token-chart-overview-card-total-supply {
    background: linear-gradient(180deg, #851010 -7.89%, #ff0000 121.43%, #cf460b 121.43%) !important;
    border-radius: 0.5em;
    padding: 1em 1.6em;
    width: 16em;
    margin: 1em;
}

.z-token-chart-overview-card-title {
    color: #b0b0b0;
    text-transform: uppercase;
}

.z-token-chart-item-title {
    color: #ff0000;
    font-weight: bold;
    font-size: 1em;
}

.z-token-chart-overview-card-content {
    color: #FFE9A0;
    font-size: 1.2em;
}

.z-token-chart-item-container {
    padding: 1em;
    display: flex;
    flex-direction: column;
    min-width: 12em;
}

.z-token-chart-item-container.z-hover {
    background-color: rgba(255, 0, 0, 0.2);
    border-radius: 0.4em;
    cursor: pointer;
    border: 1px solid #ff0000;
}


/*.z-token-chart-item-container:hover {*/
/*    background-color: rgba(157, 0, 0, 0.2);*/
/*    border-radius: 0.4em;*/
/*    cursor: pointer;*/
/*    border: 1px solid #9D0000;*/
/*}*/

.z-token-chart-item-percentage {
    color: #FFE9A0;
}

.z-token-chart-item-token-amt {
    color: #676767;
}

.z-token-chart-wrapper {
    flex: 1;
    max-width: 40em;
}

.z-roadmap-passed {
    position: relative;
    opacity: 0.5;
}

.z-roadmap-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.z-footer {
    position: relative;
    overflow: hidden;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    padding-bottom: 2em;
    align-items: center;
    justify-content: center;
    padding-top: 3em;
}

.z-footer-bg-mask {
    position: absolute;
    /*background-color: rgba(255, 255, 255, 0.3);*/
    /*z-index: 10;*/
    width: 100%;
    top: 0;
}

.z-footer-logo {
    z-index: 100;
    width: 20%;
}

.z-footer-content-wrapper {
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.z-footer-text {
    color: #ffffff;
    font-size: 1.6em;
}

.z-footer-copyright {
    font-size: 0.8em;
    color: #ffffff;
    margin: 1em 0;
}

.z-social-icons-container {
    display: flex;
    flex-direction: row;
    padding: 1em 0;
}

.z-media-container {
    text-decoration: none;
    width: 3em;
    height: 3em;
    background: linear-gradient(180deg, #ff2e2e 0%, #ff620d 100%);
    margin: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.z-media-icon {
    width: 2em;
}

.z-section-bg {
    position: absolute;
    z-index: 1;
    width: 40%;
}

.z-nft-section-bg {
    position: absolute;
    opacity: 0.6;
}

.z-token-section {
    position: relative;
}

.z-section-mask {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
}


.z-section-roadmap-mask {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    width: 100%;
    height: 100%;
}

.z-game-system-section {
    position: relative;
}

.z-roadmap-section-container {
    position: relative;
}

.z-roadmap-section-bg {
    height: 100%;
    position: absolute;
}

.z-partner-section-bg {
    width: 100%;
    position: absolute;
    opacity: 0.6;
    top: 0;
    height: 100%;
}

.z-partner-section-container {
    position: relative;
    overflow: hidden;
}

.z-member-section-container {
    position: relative;
    overflow: hidden;
}

.z-nft-img {
    width: 90%;
    margin-top: 3em;
}

.z-nft-section-hand-img {
    position: absolute;
    opacity: 0.3;
    width: 30em;
    left: -10em;
    top: 0;
}

.z-nft-section-scratch-img {
    position: absolute;
    opacity: 0.3;
    width: 30em;
    right: -6em;
    bottom: -10em;
}

.z-audit-section-container {
    position: relative;
}

.z-weapon-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 50;
}

.z-weapon-container {
    position: relative;
}

.z-nft-weapon-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
}

.z-fair-launch-section-container {
    display: flex;
    flex-direction: row;
    z-index: 100;
    width: 100%;
    align-items: baseline;
}

.z-armory-container {
    display: flex;
    flex-direction: column;
    z-index: 100;
    width: 100%;
    align-items: center;
}

.z-gameplay-video-container {
    width: 15em;
}

.z-gameplay-video {
    width: 40em;
    height: auto;
}

.z-gameplay-video-wrapper {
    width: fit-content;
    height: fit-content;
    padding: 1em;
    position: absolute;
    right: 25%;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(50%, -50%);
    background-image: url("img/background/video_bg.png");
}

.z-weapon-upgrade-video-wrapper {
    width: fit-content;
    height: fit-content;
    padding: 1em;
    background-image: url("img/background/video_bg.png");
}

.z-weapon-desk-wrapper {
    padding: 0 2em;
}

.z-weapon-desk-img {
    width: 40em;
}

.z-weapon-upgrade-video-container {
    padding: 0 5em;
}

.z-section-container {
    font-family: Rexlia;
    color: #ff0000;
    font-size: 1.2em;
    padding: 0.4em;
    text-shadow: #420000 1px 0 10px;
    z-index: 100;
    position: absolute;
    text-align: right;
    top: 2em;
}

.z-contact-info-container {
    display: flex;
    flex-direction: row;

}

.z-section-contact-text {
    display: flex;
    flex-direction: column;
}

.z-section-contact-text {
    color: #ffffff;
    font-size: 1.6em;
    z-index: 100;
    padding-top: 1.6em;
}

.z-section-contact-row {
    padding-bottom: 0.4em;
}


textarea:focus, input:focus {
    outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #FFE9A0;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.z-swap-loading-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
}

.z-swap-loading-text {
    margin-top: 0.4em;
}


