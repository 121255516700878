.zm-menu-img {
    width: 2em;
    margin-right: 2em;
}

.zm-menu-sound-img {
    width: 2.4em;
    margin-right: 2em;
}

.zm-main-video {
    display: block;
    width: 140%;
    margin-left: 70vw;
    transform: translate(-50%);
    margin-top: 4em;
}

.zm-nft-card-container {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zm-nft-img {
    margin-top: 2em;
    width: 100%;
}

.zm-section-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    padding: 4em 2em;
    align-items: center;
}

.zm-gameplay-img {
    height: 100vh;
    z-index: 1;
    transform: translate(400px, 0);
}

.zm-two {
    background-color: #000;
    position: relative;
    height: fit-content;
}

.zm-gameplay-video-wrapper {
    width: 100vw;
    height: fit-content;
    padding: 1em;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    background-image: url(http://localhost:3001/static/media/video_bg.f7f1599….png);
    z-index: 10;
}

.zm-gameplay-video {
    width: 95%;
    height: auto;
}

.zm-section-title {
    font-family: Rexlia;
    color: #ff0000;
    font-size: 2em;
    padding: 0 1.4em;
    text-shadow: #420000 1px 0 10px;
    z-index: 100;
    text-align: center;
}

.zm-weapon-section-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.zm-weapon-upgrade-video-wrapper {
    height: fit-content;
    padding: 1em;
    background-image: url(http://localhost:3001/static/media/video_bg.f7f1599….png);
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zm-gameplay-video {
    width: 95%;
}

.zm-token-chart-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    z-index: 100;
}

.zm-section-sub-header {
    color: #FFE9A0;
    font-size: 1.2em;
    /* width: 40em; */
    text-align: center;
    margin-bottom: 2em;
    z-index: 100;
}

.zm-section-header {
    color: #FF0000;
    font-size: 1.6em;
    text-transform: uppercase;
    z-index: 100;
    font-family: Rexlia;
    text-shadow: #9d0000 0px 0 20px;
    font-weight: bold;
}

.zm-member-card {
    display: flex;
    flex-direction: column;
    /* width: 25%; */
    margin: 1em;
    align-items: center;
    border-right: #9D0000;
    border-left: #9D0000;
}

.zm-partner-section-bg {
    position: absolute;
    opacity: 0.6;
    top: 0;
    height: 100%;
}

.zm-footer-bg-mask {
    position: absolute;
    width: 120%;
    bottom: 0;
}

.zm-social-icons-container {
    display: flex;
    flex-direction: row;
}

.zm-footer-text {
    color: #ffffff;
    font-size: 1.4em;
}

.zm-roadmap-container {
    display: flex;
    z-index: 100;
    width: 100%;
    overflow: scroll;
}

.zm-armory-normal-prize-label {
    color: #31A2FF;
    padding-top: 0.4em;
    text-align: center;
}

.zm-armory-weapon-case-des {
    color: #FFE9A0;
    font-size: 1.2em;
    margin-top: 2em;
}

.zm-nft-important {
    width: 1.2em;
    vertical-align: bottom;
    margin-right: 0.4em;
}

.zm-armory-normal-prize-value-label {
    font-size: 1.2em;
    color: #31A2FF;
    padding-top: 0.4em;
}

.zm-armory-legendary-prize-value-label {
    font-size: 1.2em;
    color: #B10072;
    padding-top: 0.4em;
}

.zm-armory-elite-prize-value-label {
    font-size: 1.2em;
    color: #F68C00;
    padding-top: 0.4em;
}

.zm-header-bar {
    left: 0;
    height: 4em;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    background: #300c0c;
}

.zm-header-container {
    left: 0;
    min-height: 4em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    position: fixed;
    z-index: 1000;
}

.zm-dropdown-menu-container {
    background-color: rgba(124, 0, 0, 0.9);
    width: 50vw;
    z-index: 1000;
    color: #ffffff;
    padding: 1em;
    visibility: hidden;
}

.zm-dropdown-menu-item {
    padding: 0.5em 1em;
    font-size: 1.2em;
    text-align: right;
}

.show-menu {
    visibility: visible !important;
    animation-duration: 1s;
    animation-name: slidein;
}

.zm-main-logo {
    width: 3em;
    padding: 1.5em;
}


.zm-world-logo {
    width: 14em;
}

.zm-world-logo-container {
    position: absolute;
    left: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 50%;
    transform: translate(0, -50%);
    align-items: center;
    margin-left: 2em;
}


.zm-welcome-to-zworld {
    color: rgb(255, 0, 0);
    font-size: 1.2em;
    font-family: Rexlia;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    text-shadow: #c70000 1px 0 10px;
}

.zm-join-whitelist {
    cursor: pointer;
    font-size: 1em;
    background-color: rgb(255, 0, 0);
    border-radius: 0.2em;
    padding: 0.4em 0.8em;
    color: #FFE9A0;
    width: fit-content;
    margin-top: 1em;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    display: flex;
    font-family: Rexlia;
}

.zm-join-whitelist:active {
    transform: scale(0.95);
}

.zm-join-whitelist:hover {
    box-shadow: 1px 1px 2px rgb(33, 0, 0), 0 0 1em rgb(157, 0, 0), 0 0 0.2em rgb(33, 0, 0);
}

.zm-world-logo-bg-img {
    position: absolute;
    z-index: 1;
    left: -3em;
    width: 29em;
    bottom: 0;
}

.zm-hr {
    background: radial-gradient(49.07% 49.07% at 50% 50.93%, rgba(255, 0, 0, 0.92) 0%, #310000 100%);
    width: 100%;
    height: 1.5px;
}

.zm-weapon-container {
    position: relative;
}

.zm-token-section {
    position: relative;
}

.zm-section-mask {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
}

.zm-section-bg {
    position: absolute;
    z-index: 1;
    width: 40%;
}

.zm-token-chart-items {
    display: grid;
    grid-template-columns: auto auto;
    grid-column: 2;
    padding: 0 1em;
    flex: 1;
}

.zm-token-chart-item-title {
    color: #ff0000;
    font-weight: bold;
    font-size: 1em;
}

.zm-token-chart-item-percentage {
    color: #FFE9A0;
}

.zm-token-chart-item-token-amt {
    color: #676767;
}

.zm-token-chart-overview {
    display: flex;
    flex-direction: column;
    padding: 0 1.6em;
    flex: 1;
}

.zm-token-chart-overview-card {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.5em;
    padding: 1em 1.6em;
    width: 16em;
    margin: 1em;
}

.zm-token-chart-overview-card-title {
    color: #b0b0b0;
    text-transform: uppercase;
}

.zm-token-chart-overview-card-content {
    color: #FFE9A0;
    font-size: 1.2em;
}

.zm-section-roadmap-mask {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    width: 100%;
    height: 100%;
}

.zm-roadmap-section-bg {
    height: 100%;
    position: absolute;
}

.zm-roadmap-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.zm-roadmap-block {
    display: flex;
    flex-direction: column;
    width: 24em;
    align-items: center;
    border-right: #9D0000;
    border-left: #9D0000;
}

.zm-roadmap-passed {
    position: relative;
    opacity: 0.5;
}

.zm-member-section-container {
    position: relative;
    overflow: hidden;
}

.zm-members-container {
    display: flex;
    flex-direction: column;
    z-index: 100;
    width: 100%;
}

.zm-members-row {
    display: flex;
    flex-direction: row;
}

.zm-partner-section-container {
    position: relative;
    overflow: hidden;
}

.zm-partners-container {
    display: flex;
    flex-direction: column;
    z-index: 100;
}

.zm-partners-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.zm-partner-card {
    display: flex;
    flex-direction: column;
    width: 15em;
    height: 5em;
    margin: 1.6em;
    align-items: center;
    border-right: #9D0000;
    border-left: #9D0000;
    justify-content: center;
}

.zm-audit-section-container {
    position: relative;
}

.zm-audition-img {
    width: 12em;
}

.zm-footer {
    position: relative;
    overflow: hidden;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    padding-bottom: 2em;
    align-items: center;
    justify-content: center;
    padding-top: 3em;
}

.zm-footer-content-wrapper {
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.zm-footer-logo {
    z-index: 100;
    width: 14em;
}

.zm-media-container {
    text-decoration: none;
    width: 3em;
    height: 3em;
    background: linear-gradient(180deg, #ff2e2e 0%, #ff620d 100%);
    margin: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.zm-footer-copyright {
    font-size: 0.8em;
    color: #FFE9A0;
    margin: 1em 0;
}

.zm-one {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto !important;
    position: relative;
    overflow: hidden;
}

.zm-two {
    background-color: #000;
    position: relative;
    height: fit-content;
}

.zm-three {
    background-color: #000;
    position: relative;
    height: auto;
}

.zm-four {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    padding: 4em 2em;
}

.zm-nft-section {
    position: relative;
    background-color: #000000;
    overflow: hidden;
    padding: 4em 2em;
}

.zm-game-system-section {
    position: relative;
}

.zm-weapon-section-container {
    display: flex;
    flex-direction: column;
    /*position: absolute;*/
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    direction: rtl;
    z-index: 100;
    padding: 6em 4em;
    align-items: center;
}


.zm-section-sub-title {
    font-family: Rexlia;
    color: #ffffff;
    font-size: 1.2em;
    padding: 0 0.4em;
    text-align: center;
}

.zm-weapon-desk-wrapper {
    padding: 0 2em;
}

.zm-weapon-upgrade-video-container {
    padding: 0 5em;
}

.zm-weapon-desk-img {
    width: 40em;
}

.zm-nft-section-bg {
    position: absolute;
    opacity: 0.6;
}

.zm-nft-section-hand-img {
    position: absolute;
    opacity: 0.3;
    width: 30em;
    left: -10em;
    top: 0;
}

.zm-nft-section-scratch-img {
    position: absolute;
    opacity: 0.3;
    width: 30em;
    right: -6em;
    bottom: -10em;
}

.zm-nft-weapon-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
}

.zm-section-title {
    font-family: Rexlia;
    color: #ff0000;
    font-size: 1.6em;
    padding: 0.4em;
    text-shadow: #420000 1px 0 10px;
    z-index: 100;
}

.zm-section-nft-sub-title {
    font-family: Rexlia;
    color: #FFE9A0;
    font-size: 1.2em;
    padding: 0 0.6em;
    display: table;
}

.zm-section-nft-sub-title-row {
    display: table-row;
    padding-top: 0.8em;
}

.zm-section-nft-sub-title-cell {
    display: table-cell;
    padding-top: 0.8em;
}

.zm-token-chart-overview-card-total-supply {
    background: linear-gradient(180deg, #851010 -7.89%, #ff0000 121.43%, #cf460b 121.43%) !important;
    border-radius: 0.5em;
    padding: 1em 1.6em;
    width: 16em;
    margin: 1em;
}

@keyframes slidein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.zm-menu-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.zm-pitch-deck-btn {
    background: #FF0000;
    color: #FFE9A0;
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    margin-right: 2em;
    border-radius: 0.2em;
    font-size: 1.1em;
}

.zm-game-download {
    cursor: pointer;
    font-size: 1.6em;
    background-color: rgb(255, 0, 0);
    border-radius: 0.2em;
    padding: 0.2em 0.8em;
    color: #ffffff;
    width: fit-content;
    margin-top: 1em;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    display: flex;
    font-family: Rexlia;
    margin-right: 0.4em;
    flex-direction: row;
    align-items: center;

}

.zm-game-download-disabled {
    cursor: default;
    font-size: 1.6em;
    background-color: rgb(152, 19, 19);
    border-radius: 0.2em;
    padding: 0.2em 0.8em;
    color: #858585;
    width: fit-content;
    margin-top: 1em;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    display: flex;
    font-family: Rexlia;
    margin-right: 0.4em;
    flex-direction: row;
    align-items: center;
}

.zm-game-download-container {
    display: flex;
    flex-direction: row;
}

.zm-game-download-icon {
    height: 1em;
    margin-right: 0.4em;
    color: #858585;
}

.zm-game-download-wrapper {
    display: flex;
    flex-direction: column;
}

.zm-game-available-in {
    font-size: 0.8em;
    color: #FFFFFF;
    padding-top: 0.4em;
}

.zm-fair-launch-section-container {
    display: flex;
    flex-direction: column;
    z-index: 100;
    width: 100%;
    align-items: baseline;
}

.zm-swap-container {
    border: 1px solid rgb(255, 0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1em;
    padding: 2em;
    position: relative;
    margin-top: 2em;
}

.zm-swap-container-mask-hint {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
}

.zm-swap-container-mask {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 18px;
}

.zm-swap-amount-input {
    border-radius: 0.6em;
    height: 3em;
    border: 1px solid rgb(255, 0, 0);
    background: transparent;
    caret-color: red;
    color: rgb(255, 0, 0);
    padding: 0 1em;
    text-align: right;
    font-size: 1.2em;
}

.zm-swap-add-coin-to-wallet-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF0000;
    padding: 0.3em 0.6em;
    border-radius: 0.2em;
    margin-left: 0.4em;
    cursor: pointer;
}

.zm-swap-add-coin-to-wallet-img {
    width: 3.2em;
}

.zm-swap-btn {
    color: #FFE9A0;
    background-color: #ff0000;
    width: 100%;
    padding: 0.6em 0;
    cursor: pointer;
    border-radius: 0.4em;
    text-align: center;
    font-size: 1.4em;
}

.zm-swap-sell-coin-title {
    color: #FFE9A0;
    font-size: 1.4em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.zm-fair-launch-rule-table {
    display: table;
}

.zm-fair-launch-rule-table-row {
    display: table-row;
}

.zm-fair-launch-rule-table-cell {
    display: table-cell;
    padding-right: 0.4em;
}

.zm-swap-container-connect-btn {
    background-color: #FF0000;
    color: #FFE9A0;
    padding: 0.4em 2em;
    text-align: center;
    border-radius: 0.2em;
    cursor: pointer;
    font-size: 1.4em;
}

.zm-swap-container-mask-hint-text {
    color: rgb(255, 0, 0);
    margin-bottom: 0.6em;
    font-size: 1.2em;
}

.zm-swap-loading-text {
    margin-top: 0.4em;
    font-size: 1.2em;
}

.zm-armory-section {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #000000;
    overflow: hidden;
    padding: 4em 2em;
}

.zm-armory-weapon-case-container-row {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    align-items: center;
}

.zm-armory-weapon-case-container {
    display: flex;
    flex-direction: column;
}

.zm-armory-arrow-img {
    width: 3em;
    transform: rotate(90deg);
}

.zm-armory-prize-cell {
    /* display: table-cell; */
    padding: 0 1em;
    vertical-align: middle;
}

.zm-armory-price-container {
    display: flex;
    flex-direction: row;
}

.zm-armory-arrow-cell {
    display: table-cell;
    padding: 0.4em 1em;
    vertical-align: middle;
}

.zm-armory-elite-prize-label {
    color: #F68C00;
    text-align: center;
}

.zm-armory-legendary-prize-label {
    color: #B10072;
    text-align: center;
}

.zm-section-nft-sub-title-cell-bullet {
    padding-right: 0.4em;
}

.zm-armory-blind-box-img {
    width: 20em
}

.zm-armory-blind-box-text {
    color: #FFE9A0;
    font-size: 1.2em;
    padding: 0 0 3em 0;
}

.zm-normal-weapon-blind-box {
    color: #31A2FF;
}

.zm-elite-weapon-blind-box {
    color: #F68C00;
}

.zm-legendary-weapon-blind-box {
    color: #B10072;
}

.z-btn-disabled {
    background-color: gray;
    color: #dcdcdc;
}










